





































































































































































































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab4Results',
  components: {ChemicalLatex, CalculationInput, StembleLatex, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        massBenzil: null,
        massNaBH4: null,
        molBenzil: null,
        molNaBH4: null,
        massProduct: null,
        molarMassProduct: null,
        molProduct: null,
        pctYield: null,
        mpCrude: null,
        mpRecrystallized: null,
        sampleCalc: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      tlcPlates: [] as File[],
      calculations: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.tlcPlates, ...this.calculations];
    },
  },
});
